import axios from 'axios';
import split from 'lodash/split';
import last from 'lodash/last';
import includes from 'lodash/includes';

import { HTTP_STATUS_CODES, getValue, setCookie } from '@emobg/web-utils';

import { useAuth } from '@/composable/Api/Auth/auth';
import { MFA } from '@/constants/mfa';

// Response interceptor to check if response
// status is a 401. In this case, use must be
// redirect to home.
axios.interceptors.response.use(response => response,
  // Do something with response error
  error => {
    const { logout } = useAuth();

    const allowedForbiddenEndpoints = ['from-idp'];

    const status = getValue(error, 'response.status', '');
    const errorKey = getValue(error, 'response.data.key', '');

    // 2FA: 'from-idp' endpoint must be allowed to throw 403 status
    const responseUrl = getValue(error, 'response.config.url', '');
    const lastSectionURI = last(split(responseUrl, '/'));
    const isAllowedForbiddenStatus = includes(allowedForbiddenEndpoints, lastSectionURI);

    const shouldLogout = !status
      || status === HTTP_STATUS_CODES.unauthorized
      || (status === HTTP_STATUS_CODES.forbidden && errorKey === MFA.mfaRequiredErrorKey && !isAllowedForbiddenStatus);

    if (shouldLogout) {
      logout();
    }

    if (status === HTTP_STATUS_CODES.paymentRequired) {
      setCookie('force_reset_password', 'true', { domain: 'ondemand.europcar.com' });
      window.location.replace('/reset-password/request');
    }

    return Promise.reject(error);
  });
